import React from "react";

import RightShape from "../assets/images/experts-right-shape.png";
import QuoteShape from "../assets/images/experts-quote-shape.png";
import User1 from "../assets/images/user1.png";
import User2 from "../assets/images/user2.png";
import LazyImage from "../widgets/LazyImage";

const WhatExpertsSay = () => {
  return (
    <div className="relative py-20 md:py-28 2xl:py-40">
      <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 h-[50%] w-[80%] block rounded-full bg-lrcircle blur-[150px] pointer-events-none" />
      <LazyImage
        src={RightShape}
        className="absolute bottom-0 right-0 max-h-[90%] w-[100px] md:w-[150px] 2xl:w-[330px] object-right pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <div className="container relative z-10">
        <div className="titleWrap">
          <h2 className="relative h2Title notMargin w-fit mx-auto">
            <LazyImage
              src={QuoteShape}
              className="absolute bottom-0 right-[calc(100%+10px)] md:right-[calc(100%+30px)] h-[50px] w-[62px] md:h-[105px] md:w-[130px]"
              alt="Quote shape"
              title="Quote shape"
              loading="lazy"
            />
            What Experts Are Saying
          </h2>
        </div>
        <div className="grid grid-cols-12 items-center gap-y-6 lg:gap-x-10 min-h-[280px]">
          <div className="col-span-7 sm:col-span-5 lg:col-span-3 card h-40 lg:h-full lg:!rounded-[44px] overflow-hidden relative">
            <LazyImage
              src={User1}
              alt="Jane Doe - Community Builder"
              title="Jane Doe - Community Builder"
              className="absolute h-full w-full object-cover object-center"
            />
          </div>
          <div className="col-span-12 lg:col-span-9 relative flex flex-col items-left justify-center h-full card py-5 2xl:py-7 px-6 md:px-10 2xl:px-14 lg:!rounded-[44px]">
            <p className="text-xl 2xl:text-2xl font-extrabold text-white">
              Jane Doe
            </p>
            <p className="text-sm font-medium text-white mt-0.5 mb-5">
              Community Builder
            </p>
            <p className="text-base 2xl:text-xl font-medium text-white">
              "Since joining Societi, I've experienced a level of engagement and
              collaboration that has exceeded my expectations. The platform's
              rewards system incentivizes active participation, making it a
              truly rewarding experience."
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center gap-y-6 lg:gap-x-10 min-h-[280px] mt-10">
          <div className="col-span-7 sm:col-span-5 lg:col-span-3 lg:order-2 card h-40 lg:h-full lg:!rounded-[44px] overflow-hidden relative">
            <LazyImage
              src={User2}
              alt="John Smith - Societi Member"
              title="John Smith - Societi Member"
              className="absolute h-full w-full object-cover object-center"
            />
          </div>
          <div className="col-span-12 lg:col-span-9 lg:order-1 relative flex flex-col items-left justify-center h-full card py-5 2xl:py-7 px-6 md:px-10 2xl:px-14 lg:!rounded-[44px]">
            <p className="text-xl 2xl:text-2xl font-extrabold text-white">
              John Smith
            </p>
            <p className="text-sm font-medium text-white mt-0.5 mb-5">
              Societi Member
            </p>
            <p className="text-base 2xl:text-xl font-medium text-white">
              "Societi has provided us with the tools and support we need to
              create a vibrant and inclusive digital community. The customizable
              features and AI-assisted management have been instrumental in our
              success."
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatExpertsSay;
