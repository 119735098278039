import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const Faq = () => {
  const [open, setOpen] = useState(1);

  const faqData = [
    {
      id: 1,
      que: "What is Societi?",
      ans: "Societi is the first of its kind fully decentralized digital society that transforms users into owners and decision-makers. Our platform harnesses the power of blockchain technology and artificial intelligence to provide unprecedented control and ownership to community founders and members alike.",
    },
    {
      id: 2,
      que: "How does tokenized engagement work?",
      ans: "Tokenized engagement at Societi means that the real-world economic value of the platform is directly linked to user activities. Every like, comment, and interaction helps to determine the minting rate and value of Societi Tokens, aligning community growth with token scarcity and value.",
    },
    {
      id: 3,
      que: "What are Societi Engagement Rewards?",
      ans: "Societi rewards its members for their contributions through a unique point system. Members earn points for engaging activities like posting or commenting, which can then be redeemed for the rights to mint Societi Tokens, providing a tangible value back to users for their engagement.",
    },
    {
      id: 4,
      que: "What benefits do community founders receive on Societi?",
      ans: "Founders gain full ownership over their digital spaces with the ability to profoundly customize and monetize their communities. From integrating unique AI models to leveraging robust blockchain functionalities, Societi equips founders with a comprehensive toolkit to maximize engagement, growth, and revenue.",
    },
    {
      id: 5,
      que: "How can I participate in the DAO?",
      ans: "Holding Societi Tokens grants you the power to participate in our Decentralized Autonomous Organization (DAO). This participation ranges from proposing new platform features to voting on key decisions that shape the trajectory of Societi, ensuring that every voice has the potential to influence the platform's future.",
    },
    {
      id: 6,
      que: "How does Societi differ from other social platforms?",
      ans: "Unlike traditional platforms, Societi is built on the principles of decentralization and user empowerment. Every member enjoys true ownership—be it through tokenized assets or decision-making authority via the DAO. Our innovative use of AI and blockchain technologies ensures a secure, transparent, and equitable environment for all users.",
    },
    {
      id: 7,
      que: "What makes the Societi Builder Program unique?",
      ans: "The Societi Builder Program is designed to reward users who contribute to platform growth.. Unlike typical referral programs, our system allows contributors to earn continuously from the activities of their invites, enriching community engagement and platform vitality.",
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": ${JSON.stringify(
              faqData.map((item) => ({
                "@type": "Question",
                key: item.que,
                name: item.que,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: item.ans,
                },
              }))
            )}
          }
        `}
        </script>
      </Helmet>
      <div className="relative py-20 md:py-28 2xl:py-36">
        <div className="container relative z-[1]">
          <div className="relative text-center mb-12 md:mb-20 2xl:mb-24">
            <h2 className="text-2xl md:text-4xl 2xl:text-5xl !leading-tight mb-4 md:mb-6 2xl:mb-10 font-black uppercase text-white max-w-3xl mx-auto">
              Frequently Asked Questions
            </h2>
            <p className="text-white font-medium text-base md:text-xl 2xl:text-2xl">
              Got questions? We have compiled answers to some of the most
              frequently asked questions about Societi to help you understand
              our platform better and to get started with ease. Dive into our
              FAQ section to uncover more about our revolutionary approach to a
              fully decentralized digital society.
            </p>
          </div>
          <div className="relative flex flex-col gap-4 2xl:gap-5">
            {faqData.map((items, i) => (
              <div
                key={i}
                className="relative bg-dark rounded-2xl overflow-hidden shadow-2xl"
              >
                <button
                  type="button"
                  onClick={() => setOpen(items.id)}
                  className="py-4 pl-6 md:pl-8 pr-12 rounded-lg bg-body w-full flex items-center justify-between text-left"
                >
                  <p className="text-white text-lg 2xl:text-xl font-bold">
                    {items.que}
                  </p>
                  <span className="absolute right-5 flex items-center justify-center text-xl 2xl:text-2xl text-white">
                    {items.id === open ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </button>
                <div
                  className={`relative ${
                    items.id === open &&
                    "!max-h-[300px] !h-auto overflow-auto overflow-x-hidden"
                  } h-0 bg-[linear-gradient(135deg,_rgba(33,13,66,1)_70%,_rgba(231,141,84,0.3)_100%)] trans`}
                >
                  <div className="relative py-5 px-6 md:px-8">
                    <p className="text-base 2xl:text-lg text-white">
                      {items.ans}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
