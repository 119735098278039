"use client"; // This is a client component 👈🏽

/* eslint-disable react/display-name */
import React from "react";
import { Link } from "react-router-dom";

const Button = ({
  className,
  title,
  icon = false,
  labelFirst = true,
  href = false,
  onClick,
  disabled,
  isLink = false,
  rightIcon = false,
  variant = "primary",
  ...other
}) => {
  const btnClass = `relative h-[44px] md:h-[50px] 2xl:h-[70px] w-auto inline-flex items-center rounded-tl-2xl md:rounded-tl-3xl 2xl:rounded-tl-[36px] rounded-br-2xl md:rounded-br-3xl 2xl:rounded-br-[36px] px-4 md:px-6 2xl:px-8 gap-4  font-bold text-base md:text-xl 2xl:text-[28px]  border-2 border-teal !outline-none whitespace-nowrap ${
    variant === "primary"
      ? "text-white bg-btnBg hover:bg-btnHoverBg"
      : "text-dark hover:text-white bg-white hover:bg-btnHoverBg"
  } ${className}`;

  const BtnInner = () => {
    return (
      <>
        {!labelFirst && icon}
        {title}
        {labelFirst && icon}
      </>
    );
  };

  return isLink === true ? (
    <Link
      to={href}
      className={btnClass}
      disabled={disabled}
      onClick={onClick}
      {...other}
    >
      {BtnInner()}
    </Link>
  ) : (
    <button
      type="button"
      className={btnClass}
      disabled={disabled}
      onClick={onClick}
      {...other}
    >
      {BtnInner()}
    </button>
  );
};

export default Button;
