import React from "react";

import { FaPlay, FaPause } from "react-icons/fa";

const VideoPlayIcon = ({ isPlaying = false }) => {
  return (
    <span className="group flex-center bg-dark hover:bg-white border border-white h-20 w-20 md:h-28 md:w-28 lg:h-40 lg:w-40 2xl:h-48 2xl:w-48 shadow-xl rounded-full">
      {isPlaying ? (
        <FaPause className="size-8 md:size-12 lg:size-14 2xl:size-16 fill-[url(#blue-gradient)]" />
      ) : (
        <FaPlay className="size-8 md:size-12 lg:size-14 2xl:size-16 fill-[url(#blue-gradient)] " />
      )}
      <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="0%" x2="0%" y2="0%">
          <stop stopColor="white" />
          <stop stopColor="#9f7afa" offset="0%" />
          <stop stopColor="#1117AA" offset="100%" />
        </linearGradient>
      </svg>
    </span>
  );
};

export default VideoPlayIcon;
