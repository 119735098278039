import React, { useState } from "react";
import RightFullShape from "../assets/images/ownership-right-shape.png";
import LeftShape from "../assets/images/discover-left-shape.png";
import EcosystemBtnCard from "./Cards/EcosystemBtnCard";
import EcosystemListCard from "./Cards/EcosystemListCard";

import IconCoreInnovation from "../assets/images/eco-core-Innovation.png";
import IconSupportiveEcosystem from "../assets/images/eco-supportive-ecosystem.png";
import IconCommunityGrowth from "../assets/images/eco-community-growth.png";

import IconOwnYourDigitalSpace from "../assets/images/icon-Own-Your-Digital-Space.png";
import IconEngageEarn from "../assets/images/icon-Engage-Earn.png";
import IconPowerYourHands from "../assets/images/icon-Power-in-Your-Hands.png";

import IconToolsThatEmpower from "../assets/images/icon-Tools-That-Empower.png";
import IconMonetizationOpportunities from "../assets/images/icon-Monetize-Your-Influence.png";
import IconBuildAndInnovate from "../assets/images/icon-Build-and-Innovate.png";

import IconCollaborateCreate from "../assets/images/icon-Collaborate-Create.png";
import IconLearnGrow from "../assets/images/icon-Learn-Grow.png";
import IconRewardsThatInspire from "../assets/images/icon-Rewards-That-Inspire.png";
import LazyImage from "../widgets/LazyImage";

const DiscoverEcosystem = () => {
  const [tab, setTab] = useState(1);

  const EcosystemBtnList = [
    {
      id: 1,
      img: IconCoreInnovation,
      title: "core Innovation",
    },
    {
      id: 2,
      img: IconSupportiveEcosystem,
      title: "Supportive Ecosystem",
    },
    {
      id: 3,
      img: IconCommunityGrowth,
      title: "community growth",
    },
  ];

  const CoreInnovationList = [
    {
      img: IconOwnYourDigitalSpace,
      title: "Own Your Digital Space",
      description:
        "Gain unprecedented control and ownership over your digital interactions with blockchain-enabled tokenization.",
    },
    {
      img: IconEngageEarn,
      title: "Engage and Earn",
      description:
        "Transform every interaction into an opportunity; earn tokens through active and meaningful participation.",
    },
    {
      img: IconPowerYourHands,
      title: "Power in Your Hands",
      description:
        "Participate in the democratic process that shapes the future of Societi through our Decentralized Autonomous Organization.",
    },
  ];

  const SupportiveEcosystemList = [
    {
      img: IconToolsThatEmpower,
      title: "Tools That Empower",
      description:
        "Access a suite of powerful tools designed to enhance user autonomy and facilitate robust community management.",
    },
    {
      img: IconMonetizationOpportunities,
      title: "Monetize Your Influence",
      description:
        "Explore diverse avenues for earning through your content, collaborations, and community interactions.",
    },
    {
      img: IconBuildAndInnovate,
      title: "Build and Innovate",
      description:
        "Create and integrate custom applications, enhancing functionality and user engagement with comprehensive API access.",
    },
  ];

  const CommunityGrowthList = [
    {
      img: IconCollaborateCreate,
      title: "Collaborate and Create",
      description:
        "Join forces with like-minded individuals to innovate, share, and grow within Societi’s vibrant ecosystem.",
    },
    {
      img: IconLearnGrow,
      title: "Learn and Grow",
      description:
        "Advance your skills and knowledge through continuous learning opportunities within our community hubs.",
    },
    {
      img: IconRewardsThatInspire,
      title: "Rewards That Inspire",
      description:
        "Engage with the platform and be rewarded not just for participation but for adding value to the community.",
    },
  ];

  return (
    <div className="relative py-20 md:py-28 2xl:py-36 bg-sec">
      <LazyImage
        src={LeftShape}
        className="absolute -left-0 -top-16 md:-top-20 2xl:-top-28 w-[60px] md:w-[80px] 2xl:w-[115px] pointer-events-none"
        alt="Left side background shape"
        title="Left side background shape"
        loading="lazy"
      />
      <LazyImage
        src={RightFullShape}
        className="absolute top-2/4 -translate-y-2/4 right-0 max-h-[90%] max-w-[80%] object-scale-down object-right pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 h-[80%] w-[80%] rounded-full bg-[linear-gradient(289.03deg,_rgba(36,0,255,0.46)_43.53%,_rgba(189,0,255,0.46)_89.44%)] blur-[50px] lg:blur-[150px]" />

      <div className="container relative z-10">
        <div className="titleWrap text-center">
          <h2 className="h2Title">Discover the Societi Ecosystem</h2>
          <p className="subTitle">
            Explore the Features that Define Our Community
          </p>
        </div>

        <div className="relative flex flex-col md:flex-row flex-wrap gap-4 md:gap-10 2xl:gap-24">
          {EcosystemBtnList.map((items, i) => (
            <EcosystemBtnCard
              key={i}
              items={items}
              onMouseOver={() => setTab(items.id)}
              className={`${tab === items.id && "bg-primary"} hover:bg-primary`}
            />
          ))}
        </div>

        <div className="card grid grid-cols-1 md:grid-cols-3 max-md:py-3 mt-10 2xl:mt-16">
          {tab === 1 &&
            CoreInnovationList.map((items, i) => (
              <EcosystemListCard key={i} items={items} />
            ))}
          {tab === 2 &&
            SupportiveEcosystemList.map((items, i) => (
              <EcosystemListCard key={i} items={items} />
            ))}
          {tab === 3 &&
            CommunityGrowthList.map((items, i) => (
              <EcosystemListCard key={i} items={items} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default DiscoverEcosystem;
