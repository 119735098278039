import React from "react";

import DeskImg from "../assets/images/desk-roadmap.svg";
import MobileImg from "../assets/images/mobile-roadmap.svg";
import DeskMobileImage from "../widgets/DeskMobileImage";

const SocietiRoadmap = () => {
  return (
    <div className="relative py-20 md:py-28 2xl:py-40">
      <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 h-[400px] w-[80%] block rounded-full bg-lrcircle blur-[80px] pointer-events-none" />

      <div className="container relative z-10">
        <div className="titleWrap text-center">
          <h2 className="h2Title max-w-3xl mx-auto">
            The Future Unfolds: A Glimpe Into Societi's Roadmap
          </h2>
          <p className="subTitle">
            Societi is on a visionary path, constantly advancing the frontier of
            digital communities. Below is a preview of key developments that
            will define the future of our platform, emphasizing our commitment
            to innovation, security, and user empowerment.
          </p>
        </div>
        <DeskMobileImage
          deskImg={DeskImg}
          mobileImg={MobileImg}
          className="w-full h-auto"
          alt="The Future Unfolds: A Glimpe Into Societi's Roadmap"
          title="The Future Unfolds: A Glimpe Into Societi's Roadmap"
        />
        <div className="sr-only">
          <h3>Advanced AI Integration</h3>
          <p>
            We're enhancing our AI-driven capabilities to offer intelligent
            content curation, personalized user experiences, and efficient
            community moderation, making interactions more meaningful and
            management seamless.
          </p>
          <h3>Full Blockchain Implementation</h3>
          <p>
            Transitioning to our proprietary blockchain to heighten security,
            increase scalability, and achieve complete decentralization,
            reinforcing true community ownership and trust at every level.
          </p>
          <h3>Customizable AI Models</h3>
          <p>
            Empower your community with bespoke AI solutions. Founders can
            tailor AI models to enhance engagement, optimize management, and
            create personalized experiences for members.
          </p>
          <h3>Global Expansion Strategy</h3>
          <p>
            Our expansion plans include multi-lingual support and regional
            customization to welcome a worldwide audience, making Societi
            accessible and relevant across different cultures and geographies.
          </p>
          <h3>Sustainability & Ethics Focus</h3>
          <p>
            Societi prioritizes sustainable practices and ethical standards in
            all operations, ensuring our platform not only thrives long-term but
            also contributes positively to both social and environmental
            ecosystems.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SocietiRoadmap;
