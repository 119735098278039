import React, { useState, useEffect } from "react";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <button
        type="button"
        className={`fixed bottom-2.5 right-2.5 md:bottom-4 md:right-4 size-8 md:size-10 bg-btnBg hover:bg-btnHoverBg z-30 rounded-lg border-2 border-white text-white flex-center transition-all ${
          showTopBtn ? "scale-100" : "scale-0"
        }`}
        title="Go to top"
        onClick={goToTop}
      >
        <svg
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-white size-5 md:size-6"
        >
          <path
            d="M2.625 5.71875L6 2.34375L9.375 5.71875M6 2.8125V9.65625"
            stroke="currentColor"
            stroke-width="1.125"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

export default ScrollToTop;
