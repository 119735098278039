import React from "react";

import LeftShape from "../assets/images/howworks-left-shape.png";
import RightShape from "../assets/images/howworks-right-shape.png";

import DeskImg from "../assets/images/desk-how-works.svg";
import MobileImg from "../assets/images/mobile-how-works.svg";
import DeskMobileImage from "../widgets/DeskMobileImage";
import LazyImage from "../widgets/LazyImage";

const HowDoesWork = () => {
  return (
    <div className="relative py-20 md:py-28 2xl:py-40 bg-sec">
      <LazyImage
        src={LeftShape}
        className="absolute top-2/4 -translate-y-2/4 left-0 max-h-[80%] max-w-[50%] object-scale-down object-left pointer-events-no"
        alt="Top left side background shape"
        title="Top left side background shape"
        loading="lazy"
      />
      <LazyImage
        src={RightShape}
        className="absolute right-0 top-0 w-[50%] max-w-[600px] h-auto pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 h-[400px] w-[80%] block rounded-full bg-lrcircle blur-[80px] pointer-events-none" />

      <div className="container relative z-10">
        <div className="titleWrap text-center">
          <h2 className="h2Title notMargin">How does It Work?</h2>
        </div>

        <DeskMobileImage
          deskImg={DeskImg}
          mobileImg={MobileImg}
          className="max-md:max-w-md w-full h-auto mx-auto"
          alt="How does It Work?"
          title="How does It Work?"
        />
        <div className="sr-only">
          <h3>Activate & Earn</h3>
          <p>
            Engage members with high-quality content and discussions. Monetize
            your interactions through memberships, subscriptions, and custom
            affiliate programs.
          </p>
          <h3>Personalize Your Domain</h3>
          <p>
            Tailor your community with customizable templates, branding options,
            and integrated tools that reflect your vision.
          </p>
          <h3>Utilize Powerful Tools</h3>
          <p>
            Manage your community efficiently using our comprehensive suite of
            tools including AI-driven insights, a seamless marketplace, and
            integrated analytics (coming soon).
          </p>
          <h3>Launch Your Community</h3>
          <p>
            Register and set up your community’s foundation within minutes,
            selecting modules that cater to your unique needs.
          </p>
          <h3>Shape The Future</h3>
          <p>
            Influence the evolution of Societi by participating in the DAO,
            voting on new features, and proposing innovations."
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowDoesWork;
