import React from "react";
import Button from "../widgets/Button";

import RightShape from "../assets/images/layunchsec-right-shape.png";
import LeftShape from "../assets/images/layunchsec-left-shape.png";
import LazyImage from "../widgets/LazyImage";
import { JOIN_URL } from "../Constants";

const LaunchDigitalLegacy = () => {
  return (
    <div className="relative py-20 bg-sec">
      <LazyImage
        src={RightShape}
        className="absolute bottom-0 right-0 max-h-[90%] w-[200px] object-right pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <LazyImage
        src={LeftShape}
        className="absolute left-0 top-0 w-auto h-full pointer-events-none hidden lg:block"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />

      <div className="container relative z-10">
        <div className="relative text-center mb-12">
          <h2 className="h2Title">Launch Your Digital Legacy</h2>
          <p className="subTitle notMargin">
            Embrace the future of digital engagement by crafting your
            personalized community space on Societi. Begin your journey today
            and set the stage for innovation, connection, and growth. Join as a
            pioneer shaping the digital landscape.
          </p>
        </div>
        <div className="flex-center">
          <Button title="Start Building Now" isLink href={JOIN_URL} />
        </div>
      </div>
    </div>
  );
};

export default LaunchDigitalLegacy;
