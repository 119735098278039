import React from "react";
import LazyImage from "./LazyImage";

const DeskMobileImage = ({ mobileImg, deskImg, alt, className, title }) => {
  return (
    <>
      <picture>
        <source media="(min-width:767px)" srcSet={deskImg} />
        <LazyImage
          src={mobileImg}
          className={className}
          alt={alt}
          title={title}
        />
      </picture>
    </>
  );
};

export default DeskMobileImage;
