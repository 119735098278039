import React from "react";
import LeftShape from "../assets/images/empower-left-shape.png";

import DeskMasterDomain from "../assets/images/desk-master-your-domain.svg";
import MobileMasterDomain from "../assets/images/mobile-master-your-domain.svg";

import DeskEngage from "../assets/images/desk-engage-and-prosper.svg";
import MobileEngage from "../assets/images/mobile-engage-and-prosper.svg";
import DeskMobileImage from "../widgets/DeskMobileImage";
import LazyImage from "../widgets/LazyImage";

const EmpowerAndProsper = () => {
  return (
    <>
      <div className="relative py-20 md:py-28 2xl:py-36 bg-sec">
        <LazyImage
          src={LeftShape}
          className="absolute left-0 top-2/4 -translate-y-2/4 w-[60px] md:w-[110px] 2xl:w-[150px] pointer-events-none"
          alt="Left side background shape"
          title="Left side background shape"
          loading="lazy"
        />

        <div className="container relative z-10">
          <div className="relative text-center mb-12 md:mb-20 2xl:mb-32">
            <h2 className="h2Title">
              Empower <span className="font-nunito font-bold">&</span> Prosper
            </h2>
            <p className="subTitle">
              Uncover the wide-ranging benefits tailored for your active
              participation and success within our pioneering platform.
            </p>
          </div>

          <div className="relative max-w-xl md:max-w-5xl 2xl:max-w-7xl mx-auto mb-20 md:mb-28 xl:mb-40 2xl:mb-48">
            <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 h-full w-full rounded-full bg-[linear-gradient(135deg,_rgba(36,0,255,0.46)_43.53%,_rgba(189,0,255,0.46)_89.44%)] blur-[100px]" />
            <DeskMobileImage
              deskImg={DeskMasterDomain}
              mobileImg={MobileMasterDomain}
              className="relative w-full h-auto z-[1]"
              alt="Master Your Domain"
              title="Master Your Domain"
            />
            <div className="sr-only">
              <h3>Master Your Domain</h3>
              <p>
                Leverage a suite of advanced tools to construct & scale your
                personal digital community into a thriving, valuable asset.
              </p>
              <h4>Complete Ownership</h4>
              <p>
                Secure total control with blockchain technology, ensuring your
                community is unequivocally yours.
              </p>
              <h4>Innovative Toolset</h4>
              <p>
                Utilize AI enhancements for smarter management and dynamic
                community engagement.
              </p>
              <h4>Monetization Opportunities</h4>
              <p>
                Explore diverse revenue models through memberships, content
                sales, and integrated marketplaces.
              </p>
              <h4>Collaboration and Growth</h4>
              <p>
                Capitalize on network effects with cross-promotion capabilities
                and collaborative opportunities.
              </p>
              <h4>DAO Influence</h4>
              <p>
                Participate in the Societi DAO, influencing new features and
                platform evolution.
              </p>
            </div>
          </div>
          <div className="relative max-w-xl md:max-w-5xl 2xl:max-w-7xl mx-auto">
            <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 h-full w-full rounded-full bg-[linear-gradient(310deg,_rgba(36,0,255,0.46)_43.53%,_rgba(189,0,255,0.46)_89.44%)] blur-[100px]" />
            <DeskMobileImage
              deskImg={DeskEngage}
              mobileImg={MobileEngage}
              className="relative w-full h-auto z-[1]"
              alt="Engage & Prosper"
              title="Engage & Prosper"
            />
            <div className="sr-only">
              <h3>Engage & Prosper</h3>
              <p>
                Immerse yourself in a rewarding ecosystem where your
                contributions, connections, & creativity are valued.
              </p>
              <h4>Rewarding Participation</h4>
              <p>
                Earn rewards through active engagement—posting, commenting, and
                more.
              </p>
              <h4>Meaningful Connections</h4>
              <p>
                Connect with like-minded peers to share, collaborate, and create
                collectively.
              </p>
              <h4>Professional & Personal Growth</h4>
              <p>
                Access new opportunities for development and recognition within
                your interest groups.
              </p>
              <h4>Builder Program Incentives</h4>
              <p>
                Benefit from the Societi Builder Program, gaining rewards for
                sharing with other people.
              </p>
              <h4>DAO Participation</h4>
              <p>
                Shape the platform’s future by voting on decisions that impact
                the entire community landscape.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpowerAndProsper;
