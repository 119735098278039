import React from "react";
import Button from "../widgets/Button";
import MainBannerBG from "../assets/images/mainbanner.jpg";
import BenefitsBg from "../assets/images/mp-benifitsSec-bg.png";
import NFTHomeIcon from "../assets/images/mp-nftHome-icon.png";
import ExploreLeftShape from "../assets/images/mp-explorePlateform-left-shape.png";
import LazyImage from "../widgets/LazyImage";
import { BASE_URL, JOIN_URL } from "../Constants";

const MainBanner = () => {
  return (
    <>
      {/* ====== Main Banner Section ====== */}
      <section className="relative pt-[170px] sm:pt-[200px] md:pt-[250px] lg:pt-[300px] 2xl:pt-[420px] pb-20 sm:pb-24 md:pb-36 2xl:pb-44">
        <div className="absolute inset-0 pointer-events-none">
          <LazyImage
            src={MainBannerBG}
            className="w-full h-full object-cover object-center"
            alt="Hero Background"
            title="Welcome to Societi - The world's first digital society that is owned and controlled by its members."
          />
          <div className="bg-bannerGradient absolute inset-0"></div>
        </div>

        <div className="relative z-[2]">
          <div className="container">
            <h1 className="text-4xl sm:text-5xl md:text-7xl 2xl:text-8xl uppercase mb-3.5 flex flex-col text-white">
              Let's Build The Future Together With Societi
            </h1>
            <p className="text-lg sm:text-xl md:text-4xl 2xl:text-5xl text-white font-nunito font-semibold max-w-6xl !leading-tight capitalize">
              The world's first digital society that is owned and controlled by
              its members.
            </p>

            {/* ====== Shape the Future Section ====== */}
            <div className="relative px-6 md:px-12 py-8 md:py-10 bg-dark rounded-3xl 2xl:rounded-[42px] mt-16 md:mt-32 shadow-[0_0_60px_6px_#79FEFB] overflow-hidden ">
              <LazyImage
                src={BenefitsBg}
                className="absolute top-0 right-0 h-full w-auto"
                alt="background shape icon"
                title="background shape"
              />
              <div className="relative flex flex-col lg:flex-row lg:items-center gap-6 md:gap-8 lg:justify-between">
                <div className="flex flex-col md:flex-row md:items-center gap-5 md:gap-8">
                  <LazyImage
                    src={NFTHomeIcon}
                    className="w-24 md:w-32 lg:w-36"
                    alt="Ready to Shape the Future icon"
                    title="Ready to Shape the Future"
                  />
                  <div className="flex flex-col gap-2 md:gap-2.5">
                    <h2 className="font-arima font-bold text-white text-lg md:text-2xl 2xl:text-[32px] 2xl:leading-[42px]">
                      Ready to Shape the Future?
                    </h2>
                    <p className=" text-base md:text-lg 2xl:text-xl font-medium text-white max-w-xl">
                      Don't miss out on the opportunity to be among the first to
                      experience Societi's groundbreaking platform.
                    </p>
                  </div>
                </div>
                <div>
                  <Button title="Join Societi" isLink href={JOIN_URL} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-0 top-[calc(100%-400px)] size-[300px] 2xl:size-[400px] hidden md:block rounded-full bg-[#030BBF] blur-[100px] rotate-180 z-[1]" />
        <div className="absolute left-0 bottom-0 h-[100px] w-full block bg-gradient-to-t from-body to-transparent pointer-events-none" />
      </section>

      {/* ====== Explore the Ecosystem Section ====== */}
      <section className="relative pb-20 sm:pb-24 md:pb-28 2xl:pb-32">
        <LazyImage
          src={ExploreLeftShape}
          className="absolute bottom-0 left-0 max-h-[180%] max-w-[80%] object-scale-down object-right hidden lg:block pointer-events-none"
          alt="Left side background shape"
          title="Left side background shape"
        />
        <div className="container relative z-[1]">
          <div className="relative text-center">
            <h2 className="h2Title max-w-4xl mx-auto">
              Empower Your Digital Existence: Engage, Influence, and Prosper
            </h2>
            <p className="subTitle">
              Revolutionizing Community Interaction on the Blockchain
            </p>
            <p className="subTitle notMargin">
              Welcome to the forefront of digital innovation with Societi, where
              every interaction is not just meaningful but also rewarding. Dive
              into a vibrant ecosystem of decentralized communities that empower
              you with ownership and control. Here, your contributions directly
              influence the community's growth and your personal gains.
              Experience the synergy of collaboration and innovation in a
              platform that respects your voice and rewards your participation.
              Join us to shape this dynamic network, harness the benefits of
              blockchain technology, and transform how we think about digital
              relationships and economic opportunities. Societi isn't just a
              platform; it's a movement towards a more inclusive, equitable, and
              participatory digital future. Your journey towards impactful
              online engagement begins here.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBanner;
