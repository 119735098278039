import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLanding from "./page/MainLanding";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLanding />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;