import React from "react";
import LazyImage from "../../widgets/LazyImage";

const EcosystemBtnCard = ({ items, onMouseOver, className }) => {
  return (
    <>
      <div
        className={`relative card flex flex-1 md:flex-col items-center md:justify-center gap-3 md:gap-2.5 p-5 md:min-h-48 2xl:min-h-60 h-full overflow-hidden cursor-pointer ${className}`}
        onMouseOver={onMouseOver}
      >
        <LazyImage
          src={items.img}
          className="relative h-8 max-md:w-14 md:h-14 2xl:h-24 w-auto object-scale-down"
          alt={`${items.title} icon`}
          title={items.title}
          loading="lazy"
        />
        <h3 className="text-white font-nunito text-base md:text-xl 2xl:text-2xl font-extrabold text-center capitalize">
          {items.title}
        </h3>
      </div>
    </>
  );
};

export default EcosystemBtnCard;
