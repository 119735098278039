import React from "react";
import LazyImage from "../../widgets/LazyImage";

const BlogCard = ({ image, title, description }) => {
  return (
    <div className="relative border border-purple rounded-[20px] bg-dark p-4 h-full">
      <LazyImage
        src={image}
        alt={title}
        title={title}
        className="relative w-full h-[150px] sm:h-[200px] 2xl:h-[225px] rounded-xl object-cover"
        loading="lazy"
      />
      <div className="relative pt-5 px-2 2xl:px-4">
        <h3 className="text-xl md:text-3xl 2xl:text-4xl text-white mb-2 md:h-[72px] 2xl:h-20 line-clamp-1 md:line-clamp-2">
          {title}
        </h3>
        <p className="text-white text-base md:text-lg 2xl:text-xl  line-clamp-3">
          {description}
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
