import React from "react";
import Button from "../widgets/Button";

import LeftShape from "../assets/images/becomepart-left-top-shape.png";
import RightShape from "../assets/images/becomepart-right-bottom-shape.png";
import LazyImage from "../widgets/LazyImage";
import { JOIN_URL } from "../Constants";

const BecomePart = () => {
  return (
    <>
      <div className="relative py-14 sm:py-20 md:py-28">
        <LazyImage
          src={LeftShape}
          className="absolute left-0 top-0 w-auto h-auto pointer-events-none"
          alt="Left side background shape"
          title="Left side background shape"
          loading="lazy"
        />
        <LazyImage
          src={RightShape}
          className="absolute right-0 bottom-0 h-auto w-auto pointer-events-none"
          alt="right side background shape"
          title="right side background shape"
          loading="lazy"
        />
        <div className="container">
          <div className="flex flex-col md:flex-row items-center justify-between max-md:text-center gap-10">
            <div className="flex flex-col gap-2 md:gap-2.5">
              <h2 className="h2Title notMargin">Become Part Of The Movement</h2>
              <p className="subTitle notMargin">
                Sign up for Societi now and experience the future of digital
                communities
              </p>
            </div>
            <Button title="Join Now" isLink href={JOIN_URL} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomePart;
