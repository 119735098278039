import React from "react";
import LazyImage from "../widgets/LazyImage";
const IconContainer = ({ iconName, onclick, className, alt }) => {
  return (
    <div
      className={` text-white bg-btnBg hover:bg-btnHoverBg h-[40px] w-[40px] bg-blue-500 rounded-full cursor-pointer inline-flex items-center justify-center ${className}`}
      onClick={onclick}
    >
      <LazyImage src={iconName} className="h-[18px]" alt={alt} title={alt} />
    </div>
  );
};

export default IconContainer;
