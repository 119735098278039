import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyImage = ({ src, alt, title, className }) => {
  return (
    <>
      <LazyLoadImage src={src} alt={alt} title={title} className={className} />
    </>
  );
};

export default LazyImage;
