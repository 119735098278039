import React from "react";
import RightShape from "../assets/images/whysec-right-shape.png";
import LeftShape from "../assets/images/whysec-left-shape.png";

import IconClaimYourOwnership from "../assets/images/icon-Claim-Your-Ownership.png";
import IconEmpowerYourCommunity from "../assets/images/icon-Empower-Your-Community.png";
import IconMonetizeYourInteractions from "../assets/images/icon-Monetize-Your-Interactions.png";
import IconDesignYourDomain from "../assets/images/icon-Design-Your-Domain.png";
import IconLeadWithInnovation from "../assets/images/icon-Lead-With-Innovation.png";
import LazyImage from "../widgets/LazyImage";

const WhySocieti = () => {
  const ownershipList = [
    {
      img: IconClaimYourOwnership,
      title: "Claim Your Ownership",
      desc: "Shift from being a mere participant to a true owner on Societi. Here, you fully control your content, identity, and community space, enabling you to build a community that perfectly aligns with your vision and values.",
    },
    {
      img: IconEmpowerYourCommunity,
      title: "Empower Your Community",
      desc: "Step up as a leader and use Societi's powerful tools to create, manage, and grow vibrant communities. Engage deeply, cultivate meaningful connections, and drive the evolution of the platform. Your leadership sets the tone for the future.",
    },
    {
      img: IconMonetizeYourInteractions,
      title: "Monetize Your Interactions",
      desc: "Engage and get rewarded—every post, comment, and participation enriches your stake. Societi’s revolutionary tokenized engagement model ensures that your contributions don’t just add value but also give you a quantifiable stake in the community's economic ecosystem.",
    },
    {
      img: IconDesignYourDomain,
      title: "Design Your Domain",
      desc: "Utilize Societi's flexible customization options to make your community a perfect representation of your brand. Adjust layouts, select themes, and integrate features that create a compelling and unique space for your members.",
    },
    {
      img: IconLeadWithInnovation,
      title: "Lead With Innovation",
      desc: "Step into the future with Societi where cutting-edge technology including AI and blockchain not only enhances security and transparency but also opens up unprecedented opportunities for innovation. As a pioneer, you'll lead with tools that revolutionize community engagement and governance.",
    },
  ];

  return (
    <div className="relative py-20 md:py-28 2xl:py-36 bg-sec">
      <LazyImage
        src={RightShape}
        className="absolute bottom-[5%] right-0 max-h-[90%] w-[100px] md:w-[150px] 2xl:w-[290px] object-right pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <LazyImage
        src={LeftShape}
        className="absolute left-0 top-[10%] w-[100px] md:w-[150px] 2xl:w-[200px] h-auto pointer-events-none"
        alt="Left side background shape"
        title="Left side background shape"
        loading="lazy"
      />
      <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 h-[80%] w-[80%] rounded-full bg-[linear-gradient(175deg,_rgba(36,0,255,0.46)_43.53%,_rgba(189,0,255,0.46)_89.44%)] blur-[150px] opacity-80"></div>

      <div className="container relative z-10">
        <div className="titleWrap text-center">
          <h2 className="h2Title notMargin">Why Societi?</h2>
        </div>

        <div className="relative flex flex-wrap gap-y-12 2xl:gap-y-20 items-start justify-center">
          {ownershipList.map((items, i) => (
            <>
              <div
                key={i}
                className="relative sm:w-6/12 lg:w-4/12 sm:px-5 md:px-10"
              >
                <div className="flex flex-col items-center justify-center text-center">
                  <LazyImage
                    src={items.img}
                    className="size-24 md:size-28 2xl:size-32 object-scale-down relative card p-2"
                    alt={`${items.title} icon`}
                    title={items.title}
                    loading="lazy"
                  />
                  <h3 className="text-white font-nunito text-lg md:text-xl 2xl:text-2xl font-extrabold mt-4 md:mt-7 mb-3 md:mb-5 capitalize">
                    {items.title}
                  </h3>
                  <p className="text-white text-base 2xl:text-lg font-medium max-w-md">
                    {items.desc}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhySocieti;
