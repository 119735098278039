import React from "react";
import LeftShape from "../assets/images/plateform-left-shape.png";
import RightShape from "../assets/images/plateform-right-shape.png";

import IconEmpoweredByDAO from "../assets/images/icon-Empowered-by-DAO.png";
import IconPioneeringInnovation from "../assets/images/icon-Pioneering-Innovation.png";
import IconIntegrityFirst from "../assets/images/icon-Integrity-First.png";
import LazyImage from "../widgets/LazyImage";

const WhySocieti = () => {
  const ownershipList = [
    {
      img: IconEmpoweredByDAO,
      title: "Empowered by DAO",
      desc: "Lead the change you seek. Engage in a fully democratized platform where every decision and innovation is driven by its community members.",
    },
    {
      img: IconPioneeringInnovation,
      title: "Pioneering Innovation",
      desc: "Experience cutting-edge integration of AI and blockchain designed to revolutionise connectivity and ownership.",
    },
    {
      img: IconIntegrityFirst,
      title: "Integrity First",
      desc: "Transparency and fairness aren’t just ideals; they are the pillars that support every layer of Societi.",
    },
  ];

  return (
    <div className="relative py-20 md:py-28 2xl:py-36">
      <LazyImage
        src={LeftShape}
        className="absolute top-2/4 -translate-y-2/4 left-0 max-h-[90%] max-w-[80%] object-scale-down object-right pointer-events-no"
        alt="Top left side background shape"
        title="Top left side background shape"
        loading="lazy"
      />
      <LazyImage
        src={RightShape}
        className="absolute right-0 -top-[130px] md:-top-[180px] 2xl:-top-[300px] w-[80px] md:w-[120px] 2xl:w-[200px] h-auto pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 h-[60%] w-[80%] block rounded-full bg-lrcircle blur-[150px] pointer-events-none" />

      <div className="container relative z-10">
        <div className="titleWrap">
          <h2 className="h2Title notMargin text-center">
            More Than Just a Platform, It's a Movement
          </h2>
        </div>

        <div className="relative flex flex-wrap gap-y-10 md:gap-y-12 2xl:gap-y-20 items-start justify-center">
          {ownershipList.map((items, i) => (
            <div
              key={i}
              className="relative sm:w-6/12 lg:w-4/12 sm:px-5 md:px-10 flex flex-col items-center text-center"
            >
              <LazyImage
                src={items.img}
                className="h-24 md:h-28 2xl:h-32 w-auto object-scale-down"
                alt={`${items.title} icon`}
                title={items.title}
                loading="lazy"
              />
              <h3 className="text-white font-nunito text-lg md:text-xl 2xl:text-2xl font-extrabold mt-4 md:mt-8 mb-3 md:mb-5 capitalize">
                {items.title}
              </h3>
              <p className="text-white text-base 2xl:text-lg font-medium max-w-md">
                {items.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhySocieti;
