import React from "react";
import Button from "../widgets/Button";

import RightShape from "../assets/images/revolution-right-shape.png";
import LeftShape from "../assets/images/revolution-left-shape.png";
import TopLeftShape from "../assets/images/revolution-top-left-shape.png";
import LazyImage from "../widgets/LazyImage";
import { REGISTER_URL } from "../Constants";

const JoinRevolution = () => {
  return (
    <div className="relative py-20 ">
      <LazyImage
        src={RightShape}
        className="absolute top-0 right-0 max-h-[90%] w-[100px] md:w-[150px] 2xl:w-[230px] object-right pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <LazyImage
        src={LeftShape}
        className="absolute bottom-0 left-0 h-auto w-2/4 max-w-[300px] md:max-w-[400px] pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <LazyImage
        src={TopLeftShape}
        className="absolute left-0 -top-14 md:-top-20 2xl:-top-28 w-[80px] md:w-[160px] 2xl:w-[160px] pointer-events-none"
        alt="Top Left side background shape"
        title="Top Left side background shape"
        loading="lazy"
      />
      <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 h-[50%] w-[80%] block rounded-full bg-lrcircle blur-[150px] pointer-events-none" />
      \
      <div className="container relative z-10">
        <div className="relative text-center">
          <h2 className="h2Title">Join The Revolution</h2>
          <p className="subTitle notMargin max-w-3xl 2xl:max-w-5xl mx-auto">
            "Dive into the future with Societi and unlock endless possibilities.
            Build meaningful connections, harness your passions, and help shape
            a thriving digital society."
          </p>
          <Button
            title="Register Now"
            className="my-12 lg:my-16"
            isLink
            href={REGISTER_URL}
          />
          <p className="subTitle notMargin max-w-3xl 2xl:max-w-5xl mx-auto">
            Be part of this groundbreaking movement now and create your legacy!
          </p>
        </div>
      </div>
    </div>
  );
};

export default JoinRevolution;
