import React from "react";
import LazyImage from "../../widgets/LazyImage";

const EcosystemListCard = ({ items }) => {
  return (
    <>
      <div className="relative flex flex-1 flex-col items-center justify-start py-6 px-5 2xl:p-12 text-center">
        <LazyImage
          src={items.img}
          className="h-24 md:h-28 2xl:h-32 w-auto object-scale-down"
          alt={`${items.title} icon`}
          title={items.title}
          loading="lazy"
        />
        <h3 className="text-white font-nunito text-lg md:text-xl 2xl:text-2xl font-extrabold mt-4 md:mt-7 mb-3 md:mb-5 capitalize">
          {items.title}
        </h3>
        <p className="text-white text-base 2xl:text-lg font-medium max-w-md">
          {items.description}
        </p>
      </div>
    </>
  );
};

export default EcosystemListCard;
