import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useInView } from 'react-intersection-observer';
const HeroVideo = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load the video only once when it comes into view
    threshold: 0.5,    // Load when 50% of the component is visible
  });


  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = useRef(null);

  const handlePause = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const player = playerRef.current;
      if (player) {
        const boundingRect = player.wrapper.getBoundingClientRect();
        const isCompletelyOutOfView = boundingRect.bottom < 0 || boundingRect.top > window.innerHeight;
        if (isCompletelyOutOfView && isPlaying) {
          handlePause();
        } else if (!isCompletelyOutOfView && !isPlaying) {
          // handlePlay();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPlaying]);

  return (
    <>
      <div className="relative w-full h-auto max-h-screen aspect-video flex items-center justify-center player-wrapper" ref={ref}>

        {inView && (
          <ReactPlayer
            url="https://vimeo.com/998081298/a135caaeb9?share=copy"
            width="100%"
            height="100%"
            className="react-player"
            playing={isPlaying}
            muted={true}
            controls={true}
            onPause={() => {
              if (isPlaying) {
                setIsPlaying(false)
              }
            }}
            ref={playerRef}

            onPlay={(e) => {
              setIsPlaying(true)
            }}
          />
        )}


      </div>
    </>
  );
};

export default HeroVideo;
