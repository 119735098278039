import React from "react";
import FooterWithFaq from "./Footer";
import Header from "./Header";
import { Helmet } from "react-helmet";
import ScrollToTop from "../widgets/ScrollToTop";

const Layout = ({
  title = "",
  description = "",
  bannerUrl,
  pageUrl,
  children,
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no"
        />
        <meta
          name="google-site-verification"
          content=" google site verification Code Here "
        />
        <link rel="preconnect" href="/" />
        <link rel="dns-prefetch" href="/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://wesocieti.com/${pageUrl}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={bannerUrl} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://wesocieti.com/${pageUrl}`}
        />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={bannerUrl} />
        <link rel="canonical" href={`https://wesocieti.com/${pageUrl}`} />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://wesocieti.com/${pageUrl}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://wesocieti.com/${pageUrl}`}
        />

        <script type="application/ld+json">
          {`
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Societi",
            "url": "https://wesocieti.com/",
            "logo": "https://wesocieti.com/img/logo.png",
            "alternateName": "Societi",
            "sameAs": [
              "All Social URL Media Here",
              "All Social URL Media Here",
            ]
        `}
        </script>

        <script type="application/ld+json">
          {`
          {
            "@context":"https://schema.org",
              "@graph":[
                {
                  "@type":"WebPage",
                  "@id": pageUrl,
                  "url": pageUrl,
                  "name": title,
                  "isPartOf": {
                    "@id": ${pageUrl}/#website
                  },
                  "primaryImageOfPage": {
                    "@id": ${pageUrl}/#hro
                  },
                  "image": {
                    "@id": ${pageUrl}/#hro
                  },
                  "thumbnailUrl": bannerUrl,
                  "description": description,
                  "inLanguage":"en-US",
                  "potentialAction":[
                    {
                      "@type":"ReadAction",
                      "target": [${pageUrl}]
                    }
                  ]
                },
              {
                "@type": "ImageObject",
                "inLanguage":"en-US",
                "@id": ${pageUrl}/#hro,
                "url": bannerUrl,
                "contentUrl": bannerUrl,
                "width":365,
                "height":250
              },
              {
                "@type":"WebSite",
                "@id": ${pageUrl}/#website,
                "url": pageUrl,
                "name":"Societi",
                "description":"Societi - Where Communities Thrive and Innovation Flourishes",
                "potentialAction":[
                  {
                    "@type":"SearchAction",
                    "target":{
                      "@type":"EntryPoint",
                      "urlTemplate":"https://wesocieti.com/?s={search_term_string}"
                    },
                    "query-input":"required name=search_term_string"
                  }
                ],
                "inLanguage":"en-US"
              },]
          }
        `}
        </script>

        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "url": "https://wesocieti.com/",
            "name": "Societi",
            "author": {
                "@type": "Person",
                "name": "Admin"
            },
            "description": "Societi - Where Communities Thrive and Innovation Flourishes",
            "publisher": "Admin"
          }
        `}
        </script>
      </Helmet>
      <main className="relative min-h-screen bg-body">
        <Header />
        {children}
        <FooterWithFaq />
        <ScrollToTop />
      </main>
    </>
  );
};

export default Layout;
